/* eslint-disable max-len */
import { createSvgIcon } from '../utils'

// SVG - 38 width, 38 height

const MediaPlay = createSvgIcon(
    <path d="M11 25.287V12.713c0-5.127 0-7.69 1.632-8.526 1.633-.837 3.713.661 7.874 3.657l8.731 6.287c3.086 2.221 4.629 3.332 4.629 4.869s-1.543 2.648-4.629 4.87l-8.731 6.286c-4.161 2.996-6.241 4.494-7.874 3.657C11 32.977 11 30.413 11 25.287z" />,
    'MediaPlay'
)

export default MediaPlay
