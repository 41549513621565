import styled from '@emotion/styled'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import styles from './Separator.style'

const SeparatorStyled = styled.div(props => ({ ...styles(props) }))

const Separator = ({
    children,
    className,
    vSpace,
    showWave,
    componentLeft: ComponentLeft,
    componentRight: ComponentRight,
    classNameComponentLeft,
    classNameComponentRight,
    componentLeftSocket,
    componentRightSocket,
    hasHeadingSectionLeft,
    ...rest
}) => {
    const componentLeftContent = componentLeftSocket ? (
        <div className={clsx('socket', classNameComponentLeft, showWave && 'left')}>{ComponentLeft}</div>
    ) : (
        ComponentLeft
    )
    const componentRightContent = componentRightSocket ? (
        <div className={clsx('socket', classNameComponentRight, showWave && 'right')}>{ComponentRight}</div>
    ) : (
        ComponentRight
    )

    return (
        <SeparatorStyled
            className={className}
            vSpace={vSpace}
            componentLeft={!!ComponentLeft}
            componentRight={!!ComponentRight}
            hasHeadingSectionLeft={hasHeadingSectionLeft}
            {...rest}>
            {ComponentLeft && componentLeftContent}

            {showWave && <div className="wave" aria-hidden role="presentation" />}

            {ComponentRight && componentRightContent}
        </SeparatorStyled>
    )
}

Separator.propTypes = {
    className: PropTypes.string,
    classNameComponentLeft: PropTypes.string,
    classNameComponentRight: PropTypes.string,
    vSpace: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    showWave: PropTypes.bool,
    componentLeft: PropTypes.node,
    componentRight: PropTypes.node,
    componentLeftSocket: PropTypes.bool,
    componentRightSocket: PropTypes.bool,
    hasHeadingSectionLeft: PropTypes.bool
}

Separator.defaultProps = {
    className: undefined,
    classNameComponentLeft: undefined,
    classNameComponentRight: undefined,
    vSpace: undefined,
    showWave: true,
    componentLeft: undefined,
    componentRight: undefined,
    componentLeftSocket: true,
    componentRightSocket: true,
    hasHeadingSectionLeft: false
}

export default Separator
