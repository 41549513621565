// @ui imports
import { toRem } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys */
const style = ({ theme, size, variant, disableActions }) => {
    const config = {
        small: {
            mobile: {
                height: toRem(28),
                width: toRem(28),
                fontSize: toRem(14)
            },
            desktop: {
                height: toRem(40),
                width: toRem(40),
                fontSize: toRem(20)
            }
        },
        normal: {
            mobile: {
                height: toRem(60),
                width: toRem(60),
                fontSize: toRem(28)
            },
            desktop: {
                height: toRem(60),
                width: toRem(60),
                fontSize: toRem(28)
            }
        },
        large: {
            mobile: {
                height: toRem(60),
                width: toRem(60),
                fontSize: toRem(28)
            },
            desktop: {
                height: toRem(84),
                width: toRem(84),
                fontSize: toRem(38)
            }
        },
        borderWidth: toRem(2),
        borderRadius: '100%',
        lineHeight: 1
    }

    return {
        display: 'inline-flex',
        verticalAlign: 'middle',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        width: config[size]?.mobile.width || config.normal?.mobile.width,
        height: config[size]?.mobile.height || config.normal?.mobile.height,
        margin: 0,
        padding: 0,
        border: 'none',
        borderRadius: config.borderRadius,
        outline: 0,
        backgroundColor: theme.colors.video.button.play[variant]?.background?.default,
        color: theme.colors.video.button.play[variant]?.color?.default,
        fontFamily: theme.typography.font.default,
        fontWeight: theme.typography.fontWeight.black,
        fontSize: config[size]?.mobile.fontSize || config.normal?.mobile.fontSize,
        lineHeight: config.lineHeight,
        textDecoration: 'none',
        cursor: 'pointer',
        transitionDuration: theme.transitions.button.default.duration,
        transitionTimingFunction: theme.transitions.button.default.timingFunction,
        transitionDelay: theme.transitions.button.default.delay,
        transitionProperty: 'color, background-color, border-color',
        userSelect: 'none',
        MozAppearance: 'none',
        WebkitAppearance: 'none',
        WebkitTapHighlightColor: 'transparent',
        [theme.breakpoints.up('md')]: {
            width: config[size]?.desktop.width || config.normal?.desktop.width,
            height: config[size]?.desktop.height || config.normal?.desktop.height,
            fontSize: config[size]?.desktop.fontSize || config.normal?.desktop.fontSize
        },
        ...(disableActions && {
            pointerEvents: 'none',
            cursor: 'default'
        }),
        '&:hover': {
            backgroundColor: theme.colors.video.button.play[variant]?.background?.hover,
            color: theme.colors.video.button.play[variant]?.color?.hover
        },
        '&:disabled': {
            opacity: 0.8,
            pointerEvents: 'none',
            cursor: 'default'
        },
        svg: {
            path: {
                fill: 'currentColor'
            }
        }
    }
}

export default style
