/* eslint-disable camelcase */
// @TODO: Edit button functionality
// @TODO: Check if edit is available for the logged in user (user or admin)
// @TODO: overrideImagePadding is only temporary for 2x1 card for demo purposes.

import { useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Media } from '@hmn/coolinarika-web-core/controllers/MediaContext'
import cardVariationHandler from '@hmn/coolinarika-web-core/helpers/card'
import excerpt from '@hmn/coolinarika-web-core/helpers/excerpt'
import { md, useAddRecipeToFavorites, useBreakpoints, useTaxonList, useUser } from '@hmn/coolinarika-web-core/hooks'

import { ParallaxBanner } from '../../../Banner/components/Parallax'
import { Button, buttonSizes, buttonVariants } from '../../../Button'
import { Card, cardRoundedVariants } from '../../../Card'
import { ConditionalWrapper } from '../../../ConditionalWrapper'
import { withErrorBoundary } from '../../../ErrorBoundary'
import {
    HeadingLabel,
    headingLabelSizes,
    headingLabelVariants,
    HeadingSection,
    headingSectionSizes
} from '../../../Heading/components'
import { Highlight, highlightSizes } from '../../../Highlight'
import { HtmlContent } from '../../../HtmlContent'
import { EditIcon, HeartFillIcon, HeartOutlineIcon, Icon } from '../../../Icon'
import { Image } from '../../../Image'
import { PlayButton, playButtonSizes, playButtonVariants } from '../Buttons'
import styles from './Card.style'

const VideoCardStyled = styled.div(props => ({ ...styles(props) }))

const videoLabelVariants = Object.freeze({
    PRIMARY: 'TERTIARY', // white
    SECONDARY: 'SECONDARY' // yellow
})

const videoCardVariants = Object.freeze({
    '1x1': '1x1',
    '1x2': '1x2',
    '2x1': '2x1',
    '2x2': '2x2'
})

const VideoCard = ({
    item,
    imageId: propImageId,
    imageWidth,
    imageHeight,
    itemId: propId,
    title: propTitle,
    titleTagComponent: TitleTag,
    lead: propLead,
    href,
    hrefAs,
    authorId: propAuthorId,
    className,
    categoryName,
    variant,
    labelVariant,
    overrideImagePadding,
    fullHeight,
    isParallaxed,
    usernameOnlyLabel,
    noLabel,
    showFavoritedOnHover,
    onClickEventTracking,
    onViewEventTracking,
    ...rest
}) => {
    const [isMobileUp] = useBreakpoints(md)
    const { isFavorited, handleAddToFavorites } = useAddRecipeToFavorites({ recipe: item })
    const { data } = useTaxonList('categories', {
        entity: 'articles',
        entityId: propId || item?.content_id
    })
    const {
        author,
        owned_by_user_profile,
        title: itemTitle,
        lead = propLead,
        image: itemImage
    } = useMemo(() => item || {}, [item])
    const { id: userProfileId = propAuthorId } = owned_by_user_profile || {}
    const { id: authorId = userProfileId } = author || {}
    const { id: imageId = propImageId } = itemImage || {}

    const title = propTitle || itemTitle
    const category = data?.[0]?.taxon.title || categoryName

    const { id: loggedInUserId } = useUser()

    const edit = authorId && loggedInUserId && authorId === loggedInUserId

    const showFavorited = !!loggedInUserId

    const handleEditClick = useCallback(() => {
        // placeholder function for profile edit button
        /* eslint-disable-next-line */
        alert('TODO: Edit button click action.')
    }, [])

    const cardActions = useCallback(() => {
        if (edit) {
            return (
                <Button
                    variant={buttonVariants.SECONDARY}
                    size={buttonSizes.SMALL}
                    isIconOnly
                    title="Uredi"
                    onClick={handleEditClick}>
                    <Icon icon={EditIcon} size={24} />
                </Button>
            )
        }
        if (showFavorited) {
            return (
                <Button
                    variant={buttonVariants.PRIMARY}
                    size={buttonSizes.SMALL}
                    isIconOnly
                    title={isFavorited ? 'Uredi' : 'Spremi'}
                    onClick={handleAddToFavorites}>
                    <Icon icon={isFavorited ? HeartFillIcon : HeartOutlineIcon} size={32} />
                </Button>
            )
        }
        return null
    }, [
        item,
        edit,
        handleEditClick,
        EditIcon,
        showFavorited,
        isFavorited,
        handleAddToFavorites,
        HeartFillIcon,
        HeartOutlineIcon
    ])

    const videoLabelSizeVariants = useMemo(
        () =>
            Object.freeze({
                '1x1': headingLabelSizes.SMALL,
                '1x2': headingLabelSizes.SMALL,
                '2x1': headingLabelSizes.SMALL,
                '2x2': headingLabelSizes[isMobileUp ? 'LARGER' : 'SMALL']
            }),
        [isMobileUp]
    )

    const videoHighlightSizeVariants = useMemo(
        () =>
            Object.freeze({
                '1x1': highlightSizes[isMobileUp ? 'SMALL' : 'NORMAL'],
                '1x2': highlightSizes[isMobileUp ? 'NORMAL' : 'LARGE'],
                '2x1': highlightSizes[isMobileUp ? 'NORMAL' : 'SMALL'],
                '2x2': highlightSizes[isMobileUp ? 'LARGE' : 'NORMAL']
            }),
        [isMobileUp]
    )

    const videoPlayButtonSizeVariants = useMemo(
        () =>
            Object.freeze({
                '1x1': playButtonSizes.NORMAL,
                '1x2': playButtonSizes[isMobileUp ? 'LARGE' : 'NORMAL'],
                '2x1': playButtonSizes.NORMAL,
                '2x2': playButtonSizes[isMobileUp ? 'LARGE' : 'NORMAL']
            }),
        [isMobileUp]
    )

    const HighLightContent = useCallback(() => {
        if (variant !== videoCardVariants['2x1']) {
            return (
                <Highlight
                    size={videoHighlightSizeVariants[variant]}
                    TagComponent={TitleTag}
                    className="cardContent_title">
                    {title}
                </Highlight>
            )
        }
        if (variant === videoCardVariants['2x1']) {
            return (
                <>
                    <Media lessThan="md">
                        <Highlight
                            size={videoHighlightSizeVariants[variant]}
                            TagComponent={TitleTag}
                            className="cardContent_title">
                            {title}
                        </Highlight>
                    </Media>
                    <Media greaterThan="sm">
                        <HeadingSection size={headingSectionSizes.SMALLER} className="cardContent_title">
                            {title}
                        </HeadingSection>
                    </Media>
                </>
            )
        }
        return (
            <HeadingSection size={headingSectionSizes.SMALLER} className="cardContent_title">
                {title}
            </HeadingSection>
        )
    }, [videoHighlightSizeVariants, videoPlayButtonSizeVariants, variant, title])

    if (!title && !imageId) {
        return null
    }

    return (
        <Card
            title={title}
            href={href}
            hrefAs={hrefAs}
            className={className}
            fullHeight={fullHeight}
            actionComponent={cardActions}
            rounded={cardRoundedVariants.MEDIUM}
            showFavoritedOnHover={showFavoritedOnHover}
            item={item}
            onClickEventTracking={onClickEventTracking}
            onViewEventTracking={onViewEventTracking}
            {...rest}>
            <VideoCardStyled
                variant={variant}
                videoCardVariants={videoCardVariants}
                overlay={!!lead}
                overrideImagePadding={overrideImagePadding}>
                <ConditionalWrapper condition={isParallaxed} Wrapper={ParallaxBanner} height={imageHeight * 1.7}>
                    <div className="cardPictureWrapper">
                        <Image
                            className="cardPicture"
                            fullHeight={fullHeight}
                            image={imageId}
                            placeholderBgColor={
                                itemImage?.colors?.[itemImage?.colors?.length - 1]?.hex || rest?.placeholderBgColor
                            }
                            alt={title}
                            ratio={cardVariationHandler(variant)}
                            height={imageHeight}
                            width={imageWidth}
                        />
                        <div className="cardPlay">
                            <PlayButton
                                size={videoPlayButtonSizeVariants[variant]}
                                variant={playButtonVariants.PRIMARY}
                                disableActions
                            />
                        </div>
                    </div>
                </ConditionalWrapper>

                {(!!title || !!category || !!author?.display_name) && (
                    <div className="cardContent">
                        {!noLabel && usernameOnlyLabel && (
                            <HeadingLabel
                                className="cardContent_label"
                                label={author?.display_name}
                                variant={headingLabelVariants.TERTIARY}
                                size={videoLabelSizeVariants[variant]}
                            />
                        )}
                        {!noLabel && !usernameOnlyLabel && !!category && (
                            <HeadingLabel
                                isUppercase={labelVariant === headingLabelVariants.SECONDARY}
                                className="cardContent_label"
                                variant={headingLabelVariants[labelVariant]}
                                size={videoLabelSizeVariants[variant]}>
                                <HtmlContent content={category} wrapperComponent="span" />
                            </HeadingLabel>
                        )}
                        {title && <HighLightContent />}
                        {lead &&
                            [videoCardVariants['2x1'], videoCardVariants['2x2'], videoCardVariants['1x2']].includes(
                                variant
                            ) && (
                                <Media greaterThan="sm">
                                    <div className="cardLead">{excerpt(lead, true)}</div>
                                </Media>
                            )}
                    </div>
                )}
            </VideoCardStyled>
        </Card>
    )
}

VideoCard.propTypes = {
    item: PropTypes.oneOfType([() => null, PropTypes.object]),
    imageId: PropTypes.string,
    imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    itemId: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    lead: PropTypes.string,
    href: PropTypes.string,
    hrefAs: PropTypes.string,
    authorId: PropTypes.string,
    className: PropTypes.string,
    categoryName: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(videoCardVariants)]),
    labelVariant: PropTypes.oneOf([...Object.values(videoLabelVariants)]),
    fullHeight: PropTypes.bool,
    overrideImagePadding: PropTypes.number,
    isParallaxed: PropTypes.bool,
    usernameOnlyLabel: PropTypes.bool,
    noLabel: PropTypes.bool,
    showFavoritedOnHover: PropTypes.bool,
    onClickEventTracking: PropTypes.func,
    onViewEventTracking: PropTypes.func
}

VideoCard.defaultProps = {
    item: undefined,
    imageId: undefined,
    imageWidth: 480,
    imageHeight: 360,
    itemId: undefined,
    title: undefined,
    titleTagComponent: 'h3',
    lead: undefined,
    href: undefined,
    hrefAs: undefined,
    authorId: undefined,
    className: undefined,
    categoryName: undefined,
    variant: videoCardVariants['1x1'],
    labelVariant: videoLabelVariants.SECONDARY,
    fullHeight: false,
    overrideImagePadding: undefined,
    isParallaxed: false,
    usernameOnlyLabel: false,
    noLabel: false,
    showFavoritedOnHover: false,
    onClickEventTracking: undefined,
    onViewEventTracking: undefined
}

export { videoCardVariants, videoLabelVariants }

export default withErrorBoundary(VideoCard)
