import { decorationBackgroundSVG, responsive, toRem } from '../../helpers/theme'

const style = ({ theme, vSpace, componentLeft, componentRight, hasHeadingSectionLeft }) => {
    let isResponsive = false

    if (typeof vSpace === 'object') {
        isResponsive = true
    }

    const verticalSpacing = isResponsive
        ? Object.values(vSpace).map(space => ({
              paddingTop: toRem(space),
              paddingBottom: toRem(space)
          }))
        : toRem(vSpace || 0)

    return {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        ...(isResponsive && {
            ...responsive([...verticalSpacing])
        }),
        ...(!isResponsive && {
            paddingTop: verticalSpacing,
            paddingBottom: verticalSpacing
        }),
        '.wave': {
            display: 'inline-flex',
            flex: 1,
            width: '100%',
            height: toRem(5),
            backgroundColor: 'transparent',
            backgroundImage: decorationBackgroundSVG(theme.colors.separator.default),
            backgroundRepeat: 'repeat-x',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            ...(hasHeadingSectionLeft && {
                marginTop: toRem(8),
                [theme.breakpoints.up('lg')]: {
                    marginTop: toRem(12)
                }
            })
        },
        '.socket': {
            display: 'inline-flex',
            alignItems: 'center',
            '&.left': {
                ...(componentLeft && {
                    marginRight: toRem(15)
                })
            },
            '&.right': {
                ...(componentRight && {
                    marginLeft: toRem(15)
                }),
                ...(hasHeadingSectionLeft && {
                    marginTop: toRem(8),
                    [theme.breakpoints.up('lg')]: {
                        marginTop: toRem(12)
                    }
                })
            }
        }
    }
}

export default style
