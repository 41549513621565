/**
 * @TODO: This HOC is only used due to Reflebox' ThemeProvider incompatibility with
 * Coolinarika's core ThemeProvider and various Emotion major version differences.
 *
 * We should keep track of this pull request and update when it's released:
 *  * https://github.com/rebassjs/rebass/pull/1051/files
 */
import { withTheme } from '@emotion/react'
import PropTypes from 'prop-types'
// import { Box } from 'reflexbox'
import { Box } from 'theme-ui'

const Column = ({ children, theme, disabled, ...rest }) => {
    if (disabled) {
        return null
    }

    return (
        <Box theme={theme.layoutTheme} {...rest}>
            {children}
        </Box>
    )
}

Column.propTypes = {
    disabled: PropTypes.bool
}

Column.defaultProps = {
    disabled: false
}

export default withTheme(Column)
