/* eslint-disable max-len */
import { createSvgIcon } from '../utils'

const ForkSpoon = createSvgIcon(
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.55 8.11c-1.11 0-2.331-.609-3.433-1.71C.25 4.53-.086 2.371 1.237 1.031a2.736 2.736 0 0 1 1.978-.81C4.329.221 5.54.82 6.63 1.913 8.225 3.509 8.714 5.31 8 6.642l1.992 1.991 2.48-2.48-1.074-1.076a.66.66 0 0 1 0-.934L15.363.177a.661.661 0 0 1 .934.935L12.8 4.61l.608.608L17.126 1.5a.661.661 0 0 1 .934.935l-3.719 3.719.608.608 3.94-3.94a.661.661 0 0 1 .934.934l-4.407 4.407a.661.661 0 0 1-.934 0l-1.075-1.075-2.481 2.48 5.288 5.288a.661.661 0 1 1-.934.935l-5.288-5.288-5.178 5.177a.66.66 0 1 1-.934-.934l5.178-5.177-1.935-1.935a2.728 2.728 0 0 1-1.573.476zm.145-5.262c-.797-.798-1.71-1.322-2.48-1.322a1.428 1.428 0 0 0-1.045.44c-.881.882-.322 2.292.881 3.5.802.801 1.723 1.321 2.499 1.321.385.009.756-.14 1.027-.414.859-.881.321-2.322-.882-3.525z"
        fill="#BCBDC0"
    />,
    'ForkSpoon'
)

export default ForkSpoon
