// @TODO: Better handle "disableActions" on button events

// Third party imports
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '../../../ErrorBoundary'
import { Icon, MediaPlayIcon } from '../../../Icon'
import styles from './PlayButton.style'

const PlayButtonStyled = styled.button(props => ({ ...styles(props) }))

const PlayButton = ({
    buttonRef,
    role,
    title,
    className,
    disabled,
    onBlur,
    onClick,
    onFocus,
    onKeyDown,
    onKeyUp,
    onMouseDown,
    onMouseEnter,
    onMouseLeave,
    onMouseUp,
    onDragLeave,
    onTouchEnd,
    onTouchMove,
    onTouchStart,
    size,
    variant,
    dataAttrs,
    iconComponent: CustomIcon,
    disableActions,
    ...rest
}) => {
    const getDataAttrs = () => {
        if (!dataAttrs) {
            return null
        }
        return Object.keys(dataAttrs).reduce((attrs, key) => {
            attrs[`data-${key}`] = dataAttrs[key]
            return attrs
        }, {})
    }

    return (
        <PlayButtonStyled
            ref={buttonRef}
            role={role}
            type="button"
            className={className}
            title={title}
            onBlur={onBlur}
            onClick={onClick}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseUp={onMouseUp}
            onDragLeave={onDragLeave}
            onTouchEnd={onTouchEnd}
            onTouchMove={onTouchMove}
            onTouchStart={onTouchStart}
            size={size}
            variant={variant}
            disabled={disabled}
            disableActions={disableActions}
            {...getDataAttrs()}
            {...rest}>
            {CustomIcon || <Icon icon={MediaPlayIcon} viewBox="0 0 38 38" />}
        </PlayButtonStyled>
    )
}

const playButtonVariants = Object.freeze({
    PRIMARY: 'primary',
    SECONDARY: 'secondary'
})

const playButtonSizes = Object.freeze({
    SMALL: 'small',
    NORMAL: 'normal',
    LARGE: 'large'
})

PlayButton.propTypes = {
    buttonRef: PropTypes.string,
    role: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onMouseUp: PropTypes.func,
    onDragLeave: PropTypes.func,
    onTouchEnd: PropTypes.func,
    onTouchMove: PropTypes.func,
    onTouchStart: PropTypes.func,
    size: PropTypes.oneOf([...Object.values(playButtonSizes)]),
    variant: PropTypes.oneOf([...Object.values(playButtonVariants)]),
    dataAttrs: PropTypes.shape({}),
    iconComponent: PropTypes.node,
    disableActions: PropTypes.bool
}

PlayButton.defaultProps = {
    buttonRef: undefined,
    role: undefined,
    className: undefined,
    disabled: false,
    title: undefined,
    onBlur: () => {},
    onClick: () => {},
    onFocus: () => {},
    onKeyDown: () => {},
    onKeyUp: () => {},
    onMouseDown: () => {},
    onMouseEnter: () => {},
    onMouseLeave: () => {},
    onMouseUp: () => {},
    onDragLeave: () => {},
    onTouchEnd: () => {},
    onTouchMove: () => {},
    onTouchStart: () => {},
    size: playButtonSizes.NORMAL,
    variant: playButtonVariants.PRIMARY,
    dataAttrs: undefined,
    iconComponent: undefined,
    disableActions: false
}

export { playButtonVariants, playButtonSizes }

export default withErrorBoundary(PlayButton)
