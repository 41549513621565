import PropTypes from 'prop-types'

import { ForkSpoonIcon, Icon } from '../../../Icon'
import { Row } from '../../../Layout'
import { Separator } from '../../../Separator'

const FooterSeparator = ({ className, disabled, ...rest }) => {
    if (disabled) {
        return null
    }

    return (
        <Row variant="footer.separator" {...rest}>
            <Separator componentRight={<Icon icon={ForkSpoonIcon} size={22} viewBox="0 0 20 16" />} />
        </Row>
    )
}

FooterSeparator.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool
}

FooterSeparator.defaultProps = {
    className: undefined,
    disabled: false
}

export default FooterSeparator
